import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

export default function SupplyMembership() {
  return (
    <>
      <Helmet>
        <title>Supply Membership Agreement | Para</title>
        <meta
          name="description"
          content="The Para's membership agreement should be read by any nurse that's planning download and use our per diem nursing app. Click here to read more!"
        />
        <link rel="canonical" href="https://joinpara.com/supply-membership/" />
      </Helmet>
      <div className="main">
        <div className="container privacy">
          <span>Last Updated: July 15, 2018</span>
          <h1>Para's Membership Agreement</h1>
          <p>
            Para Services, Inc. ("Para," "we" or "us) is the operator of the
            Para website located at www.joinpara.com ("Website"), the Para
            mobile application ("Para App") and other Para provided services,
            products, websites, and applications (collectively, the "Para
            Platform"). The Para Platform provides a free online marketplace for
            Medical Professional to possibly find and receive Shifts to provide
            healthcare professional services for Healthcare Providers as
            independent contractors on a temporary basis.
          </p>
          <p>
            This Para Membership Agreement ("Agreement") governs the
            relationship and establishes the rights and responsibilities of
            Para, as the platform owner and operator of, and you ("Member,"
            "you," "your"), as a member of and participant in, the Para
            Marketplace ("Para Marketplace"). The terms and definitions of
            Para’s{" "}
            <Link to="/privacy-policy/">Privacy Policy ("Privacy Policy")</Link>{" "}
            and Para’s{" "}
            <Link to="/terms-and-conditions/">
              Terms of Service ("Terms of Service")
            </Link>{" "}
            are incorporated into this Agreement.
          </p>
          <p>
            <strong>Membership</strong>
          </p>
          <p>
            As provided in this Agreement, Para will provide you with access to
            the Para Platform to possibly find and receive Shifts from
            Healthcare Providers that have access to the Para Platform and
            participate in the Para Marketplace.
          </p>
          <p>
            <strong>Eligibility</strong>
          </p>
          <p>
            To be eligible as a member of the Para Marketplace, a person must
            meet, as determined by Para in its sole discretion, the Para
            Marketplace member eligibility criteria listed below ("Membership
            Criteria") at the time of the application through the Para Platform
            and at all times thereafter: Be at least eighteen (18) years of age;
            Possess a valid U.S. driver’s license; and Possess a valid,
            professional license required to perform healthcare professional
            services that is unencumbered, that is a license that is not
            suspended, expired, revoked, and/or have incidents recorded against
            said license by the appropriate regulatory or issuing authority.
          </p>
          <p>
            Membership in the Para Marketplace is not guaranteed. Para can, at
            its sole discretion, approve, deny, suspend, and/or terminate any
            member’s membership at any time with or without reason.
          </p>
          <p>
            <strong>Relationship</strong>
          </p>
          <p>
            <strong>
              YOU EXPRESSLY AGREE THAT YOU ARE AN INDEPENDENT CONTRACTOR IN
              PARTICIPATING IN THE PARA MARKETPLACE AND USING THE PARA PLATFORM
              THIS INCLUDES FINDING,RECEIVING, AND SUBSEQUENTLY COMPLETING
              POSSIBLE SHIFTS FROM HEALTHCARE PROVIDERS AND PERFORMING ANY ACT
              OR HEALTCHARE PROFESSIONAL SERVICE RELATED TO SAID SHIFTS FOUND
              AND/OR POSSIBLY RECEIVED ON THE PARA PLATFORM. YOUR PARTICIPATION
              IN THE PARA MARKETPLACE AND USE OF THE PARA PLATFORM IS BY YOUR
              OWN VOLITION AND DISCRETION. THIS AGREEMENT DOES NOT GIVE EITHER
              PARTY (PARA OR YOU) THE POWER TO DIRECT AND CONTROL DAY TO DAY
              ACTIVITIES of the other, constitute the parties as partners, joint
              venturers, co-owners, principal-agent or otherwise participants in
              a joint or common undertaking, or, except as expressly provided
              herein, allow either party to create or assume any obligation on
              behalf of the other for any purpose whatsoever.
            </strong>
          </p>
          <p>
            <strong>Accepted Shifts</strong>
          </p>
          <p>
            Without a showing of good cause, a missed Shift or repeated
            tardiness may result in the suspension of your membership in the
            Para Marketplace and an inability to access the Para Platform for a
            period of 3 months. Missing multiple Shifts or persistent tardiness
            within a 6-month period may result in the termination of your
            membership and an inability to access the Para Platform. Para can,
            at its sole discretion, approve, deny, suspend, and/or terminate any
            Member’s membership at any time with or without reason.
          </p>
          <p>
            <strong>Review/Ratings</strong>
          </p>
          <p>
            At the end of each Shift, a Healthcare Provider may rate or provide
            a review of your healthcare professional services rendered (e.g. 1
            through 5 stars). The criteria for providing these reviews are set
            by Para and may be modified from time to time. A Member’s low score,
            resulting from multiple low ratings from Healthcare Providers, may
            result in termination of your membership and an inability to access
            the Para Platform. Para can, at its sole discretion, approve, deny,
            suspend, and/or terminate any Member’s membership at any time with
            or without reason.
          </p>
          <p>
            <strong>Additional Screening</strong>
          </p>
          <p>
            Where applicable, a Healthcare Provider may require additional
            screening requirements for its posted Shift(s) such as a criminal
            background screening including a Level 3 search in The Fraud and
            Control Information System (FACIS) database and/or a 10-panel drug
            screening, and satisfy any additional requirements defined by a
            Healthcare Provider’s staffing and/or market criteria in order to
            fulfill said posted Shift(s).
          </p>
          <p>
            You will be prompted by a third-party service provider for these
            additional screenings and it is at Member’s sole discretion to
            accept said third-party service provider’s terms and conditions and
            be subject to these additional screenings and results of said
            screenings. Failure of such screenings may disqualify you for a
            posted Shift, even after accepting said Shift, and may result in the
            termination of your membership and an inability to access the Para
            Platform.
          </p>
          <p>
            Para can, at its sole discretion, approve, deny, suspend, and/or
            terminate any Member’s membership at any time with or without
            reason.
          </p>
          <p>
            <strong>Payment, Timesheets, and Expenses</strong>
          </p>
          <p>
            Payment received through the Para Platform will reflect the hourly
            rate you agree to for an accepted job and the total number of hours
            you have provided healthcare professional services for said job. At
            the end of each Shift, a timesheet will be reviewed, updated, and
            submitted by the applicable Healthcare Provider reflecting the hours
            you have provided healthcare professional services. In the event
            there is a dispute between you and said Healthcare Provider
            regarding the number of hours you provided said healthcare
            professional services, Para will provide original Shift information
            along with any location information (e.g. GPS) in its possession
            with your written consent or at your request.
          </p>
          <p>
            <strong>
              It is although your sole responsibility, as an independent
              contractor, in resolving any dispute as to the number of hours of
              healthcare professional services rendered with the applicable
              healthcare provider. For avoidance of any doubt, Para is in no way
              responsible for any hour discrepancy between the total number of
              hours submitted on a timesheet by a healthcare provider and the
              total number of hours you claim to have provided healthcare
              professional services.
            </strong>
          </p>
          <p>
            <strong>
              You are further solely responsible for all expenses incurred in
              connection with the healthcare professional services you provide
              to any healthcare provider found through the Para Platform. For
              avoidance of any doubt, Para is in no way responsible for any
              expenses including any consequential and/or incidental expenses
              you incur related to you providing healthcare professional
              services.
            </strong>
          </p>
          <p>
            <strong>Indemnity</strong>
          </p>
          <p>
            To the extent permitted by applicable law, Member will defend,
            indemnify, hold harmless and reimburse Para and its directors,
            officers, shareholders, employees, and agents from and for all
            damages, losses, costs or expenses, including attorney’s fees,
            incurred by any of such parties or paid by any of them to any person
            in respect to a Member’s liability.
          </p>
          <p>
            <strong>Reporting Obligations</strong>
          </p>
          <p>
            Under applicable State, Federal, or local law Para may be obligated
            to report earnings, revenues, violations, crimes, incidents or
            occurrences in connection or related to your membership,
            participation in Para Marketplace, and/or use of the Para Platform.
          </p>
          <p>
            <strong>Disclaimers</strong>
          </p>
          <p>
            <strong>Disclaimer of Warranties.</strong> EXCEPT AS EXPRESSLY
            PROVIDED HEREIN, THE PARA PLATFORM, SERVICES AND SOFTWARE PROVIDED
            UNDER THIS AGREEMENT ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY
            KIND. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, PARA
            EXPRESSLY DISCLAIMS ALL OTHER WARRANTIES OF ANY KIND, EXPRESS,
            IMPLIED OR OTHERWISE, INCLUDING WITHOUT LIMITATION WARRANTIES OF
            MERCHANTABILITY, NON-INFRINGEMENT, TITLE, AND FITNESS FOR A
            PARTICULAR PURPOSE. PARA MAKES NO WARRANTY AND ASSUMES NO
            RESPONSIBILITY OR LIABILITY REGARDING ANY RELATIONAL DATABASE
            SERVICE OR SERVERS, OR ANY THIRD PARTY HARDWARE, EQUIPMENT OR
            SERVICE WITH WHICH SERVICES MAY BE REQUIRED TO COMMUNICATE OR
            OPERATE, AND DOES NOT WARRANT AND SPECIFICALLY DISCLAIMS THAT THE
            SERVICES WILL BE UNINTERRUPTED, ERROR-FREE, SUITABLE FOR CUSTOMER’S
            INTENDED USE, OR FREE OF MALICIOUS ELEMENTS OR THAT RESULTS WILL BE
            TIMELY, ACCURATE, OR COMPLETE. THE SERVICES AND SITE ARE NOT A
            BACKUP SERVICE FOR STORING CONTENT OR OTHER DATA, AND PARA SHALL
            HAVE NO LIABILITY REGARDING ANY LOSS OF DATA. THIS PROVISION WILL
            SURVIVE TERMINATION OF THIS AGREEMENT.
          </p>
          <p>
            <strong>Disclaimer of Liability.</strong> Para is not liable for any
            and all losses incurred or sustained by, or imposed upon, arising
            out of, with respect to, or by reason of member’s willful, or
            grossly negligent action or omission related to or in connection
            with member providing healthcare professional services or
            participation with the Para Marketplace and/or Para Platform. this
            provision will survive termination of this agreement.
          </p>
          <p>
            <strong>
              Disclaimer of Special, Consequential and Other Damages.
            </strong>{" "}
            Para is not liable for any and all indirect, special, punitive,
            exemplary, loss of income, loss of profits, inconvenience,
            consequential or other damages sustained as a result of an
            interruption of services to be furnished by Para. This provision
            will survive termination of this agreement
          </p>
          <p>
            <strong>Term and Termination of Membership</strong>
          </p>
          <p>
            This Agreement will commence on the date of Member’s acceptance and
            will continue in effect for one (1) year, unless earlier terminated
            as provided herein, and will automatically renew for subsequent
            periods of one (1) year unless earlier terminated as provided
            herein.
          </p>
          <p>The Agreement will be terminated as follows:</p>
          <p>
            1. Para will terminate this Agreement within 30 days of Member’s
            request;
          </p>
          <p>
            2. Immediately, in Para’s sole discretion including if Member fails
            to meet the Membership Criteria, and/or fails to abide by the terms
            of this Agreement.
          </p>
          <p>
            A notice of termination will be provided electronically. Termination
            will result in Member’s inability to participate in Para’s
            Marketplace and access the Para Platform.
          </p>
          <p>
            <strong>No Assignment</strong>
          </p>
          <p>
            Member may not assign this Agreement or any rights or
            responsibilities under this Agreement to any other party. Member may
            not all allow anyone to use Member’s account or participate in the
            Para Marketplace using Member’s account or access the Para Platform
            using Member’s account.
          </p>
          <p>
            <strong>Severability</strong>
          </p>
          <p>
            If any term or provision of this Agreement is invalid, illegal or
            unenforceable in any jurisdiction, such invalidity, illegality, or
            unenforceability will not affect any other term or provision of this
            Agreement or invalidate or render unenforceable such term or
            provision in any other jurisdiction.
          </p>
          <p>
            <strong>Dispute Resolution</strong>
          </p>
          <p>
            <strong>Mandatory Arbitration:</strong> Any dispute, claim or
            controversy arising out of or relating to membership in the Para
            Marketplace, this Agreement or the breach, termination, enforcement,
            interpretation or validity of this Agreement, including the
            determination of the scope or applicability of this agreement to
            arbitrate (a "Dispute"), will be determined by arbitration.
          </p>
          <p>
            <strong>CLASS ACTION WAIVER:</strong> TO THE EXTENT PERMISSIBLE BY
            LAW, ALL CLAIMS MUST BE BROUGHT IN THE PARTIES' INDIVIDUAL CAPACITY,
            AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, OR
            COLLECTIVE ACTION PROCEEDING (COLLECTIVELY "CLASS ACTION WAIVER").
            THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON'S CLAIMS OR
            ENGAGE IN ANY CLASS ARBITRATION. YOU AGREE THAT, BY ENTERING INTO
            THESE TERMS, YOU AND PARA ARE EACH WAIVING THE RIGHT TO A TRIAL BY
            JURY OR TO PARTICIPATE IN A CLASS ACTION.
          </p>
          <p>
            In any lawsuit in which (1) the complaint is filed as a class
            action, or collective action; and (2) the civil court of competent
            jurisdiction in which the complaint was filed finds the Class Action
            Waiver is unenforceable (and such finding is confirmed by appellate
            review if review is sought), the Class Action Waiver shall be
            severable from this Agreement and in such instances, the class
            action, and/or collective action must be litigated in a civil court
            of competent jurisdiction and not as a class, or collective
            arbitration.
          </p>
          <p>
            Notwithstanding any other clause contained in this Agreement, any
            claim that all or part of the Class Action Waiver is invalid,
            unenforceable, unconscionable, void or voidable may be determined
            only by a court of competent jurisdiction and not by an arbitrator.
            The Class Action Waiver shall be severable when a dispute is filed
            as an individual action and severance is necessary to ensure that
            the individual action proceeds in arbitration.
          </p>
          <p>
            Arbitration Procedures and Location: The arbitration will be
            administered by the American Arbitration Association ("AAA") in
            accordance with AAA Consumer Arbitration Rules. Judgment on the
            award may be entered in any court having jurisdiction. This
            provision will not preclude parties from seeking provisional
            remedies in aid of arbitration from a court of appropriate
            jurisdiction. This provision will survive termination of this
            Agreement.
          </p>
          <p>
            The arbitration will be held in the United States county where you
            live or work, Chicago, Illinois or any other location you and Para
            mutually agree upon in writing.
          </p>
          <p>
            Arbitration Fees: The arbitrator will determine how the costs and
            expenses of the arbitration will be allocated between the Parties,
            and may award attorneys’ fees.
          </p>
          <p>
            <strong>Choice of Law</strong>
          </p>
          <p>
            These Terms are governed by and construed in accordance with the
            laws of the State of Texas, U.S.A., without giving effect to any
            conflict of law principles, except as may be otherwise provided in
            the Dispute Resolution section above or in supplemental terms
            applicable to your region. However, the choice of law provision
            regarding the interpretation of these Terms is not intended to
            create any other substantive right to non-Texans to assert claims
            under Texas law whether that be by statute, common law, or
            otherwise. This provision is only intended to specify the use of
            Texas law to interpret these Terms and the forum for disputes
            asserting a breach of these Terms, and this provision shall not be
            interpreted as generally extending Texas law to you if you do not
            otherwise reside in Texas. The foregoing choice of law and forum
            selection provisions do not apply to the Dispute Resolution section
            or to any arbitrable disputes as defined therein. Instead, as
            described in the Dispute Resolution section, the Federal Arbitration
            Act (9 U.S.C., Secs. 1-16) shall apply to any such disputes.
          </p>
          <p>
            <strong>Electronic Document and Signature</strong>
          </p>
          <p>
            For contractual purposes, Member: (a) consents to receive
            communications from Para in electronic form, and (b) agrees that all
            terms and conditions, agreements, notices, disclosures and any other
            communications that Para provides to Member electronically satisfy
            any legal requirement that such communications would satisfy if they
            were in a print-on-paper writing. By selecting "I have read and
            agree to Para's Membership Agreement" or "I have read and accept to
            All" on the agreement homepage of the Para Platform Member is
            signing this Agreement electronically (Member’s "E-Signature"), and
            such selection constitutes Member’s legal signature, acceptance and
            agreement as if this Agreement were actually signed by Member in
            writing. Member also agrees that no certification, authority, or
            other third party verification is necessary to validate Member’s
            E-Signature and that lack of such certification or third party
            verification will not in any way affect the enforceability of
            Member’s E-Signature or any resulting contract between Member and
            Para.
          </p>
        </div>
      </div>
    </>
  );
}
